body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.css */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  background-color: #34495e;
  color: white;
  padding: 10px 20px;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.app-name {
  font-size: 24px;                /* Font size */
  font-weight: bold;             /* Bold text */
  color: white;                  /* Change text color to white */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6); /* Add shadow for better visibility */
  padding: 10px;                 /* Add padding for better spacing */
  border-radius: 5px;            /* Optional: Add rounded corners */
}



.main-container {
  display: flex;
  flex-grow: 1;
}

.menu {
  background-color: #2c3e50;
  color: white;
  width: 20%;
  padding: 20px;
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu ul li {
  margin: 10px 0;
}

.menu ul li a {
  color: white;
  text-decoration: none;
}

.menu ul li a:hover {
  text-decoration: underline;
}

.content {
  background-color: #ecf0f1;
  width: 80%;
  padding: 20px;
}

h1 {
  color: #2c3e50;
}

p {
  font-size: 16px;
}

.search-box {
  display: flex;
  align-items: flex-start; /* Align items to the start */
  /*justify-content: left;
  width: 100%;
  max-width: 600px;*/ /* Width of the search box */
  margin: 0 auto;
  padding: 15px; /* Padding around the search box */
  border: 3px solid #ccc; /* Border for the search box */
  border-radius: 15px; /* Rounded corners */
  background-color: #f9f9f9; /* Background color */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 15px; /* Padding inside the textarea */
  border-radius: 15px; /* Rounded corners for the textarea */
  font-size: 18px; /* Font size for the textarea */
  background-color: transparent; /* Transparent background for textarea */
  resize: none; /* Disable resizing of the textarea */
}

.search-button {
  background: none;
  border: none;
  padding: 0 15px; /* Padding for the button */
  cursor: pointer;
  font-size: 24px; /* Font size for the button */
  color: #555; /* Color for the button */
}

.search-button:hover {
  color: #333; /* Color on hover for the button */
}

.search-input::placeholder {
  color: #999; /* Color for the placeholder text */
  font-size: 16px; /* Font size for the placeholder text */
}

.select-label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}

.styled-select {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc; /* Change border color to #ccc */
  border-radius: 5px;
  background-color: #f8f9fa; /* Light Gray */
  transition: border-color 0.3s ease;
}

.styled-select:focus {
  border-color: #999; /* Change to a darker gray on focus for contrast */
  outline: none; /* Remove default outline */
}

.styled-button {
  background: #f8f9fa; /* Light gray background */
  border: 2px solid #ccc; /* Light gray border */
  border-radius: 8px;
  color: #333; /* Dark gray text for good contrast */
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); /* Light shadow for depth */
}

.styled-button:hover {
  background: #e9ecef; /* Slightly darker gray on hover */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow on hover */
}

.styled-button:active {
  transform: scale(0.98); /* Slightly scale down on click */
  background: #dee2e6; /* Darker gray on active */
}

.styled-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(192, 192, 192, 0.7); /* Light gray focus shadow */
}




